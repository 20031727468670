var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('form',{attrs:{"method":"post","id":"sendform"},on:{"submit":function($event){$event.preventDefault();return _vm.mySubmit.apply(null, arguments)}}},[_c('div',{staticClass:"container"},[_vm._m(1),_c('div',{staticClass:"tab-content"},[_c('div',{staticClass:"container tab-pane active",attrs:{"id":"home"}},[_c('br'),_c('div',{},[_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"card"},[_vm._m(2),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-10"},[_c('div',{staticClass:"form-group"},[_vm._m(3),_c('input',{staticClass:"form-control",attrs:{"type":"text","name":"name"}}),_c('p',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.error.name))])])]),_vm._m(4)])])])])])]),_c('div',{staticClass:"container tab-pane fade",attrs:{"id":"menu1"}},[_c('br'),_c('div',{},[_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"card"},[_vm._m(5),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-10"},[_c('div',{staticClass:"form-group"},[_vm._m(6),_c('input',{staticClass:"form-control",attrs:{"type":"text","name":"name_EN"}}),_c('p',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.error.name_EN))])])])])])])])])]),_c('div',{staticClass:"card-footer",staticStyle:{"width":"90%","position":"fixed","bottom":"0"}},[(!_vm.is_load)?_c('button',{staticClass:"btn btn-sm btn-primary",attrs:{"type":"submit"}},[_c('i',{staticClass:"fa fa-save"}),_vm._v(" Save ")]):_c('h5',[_vm._v("Loading...")])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('ol',{staticClass:"breadcrumb"},[_c('li',{staticClass:"breadcrumb-item"},[_vm._v("Trang chủ")]),_c('li',{staticClass:"breadcrumb-item"},[_c('a',{attrs:{"href":"/admin/questionrate/lists"}},[_vm._v("Quản lý đánh giá ")])]),_c('li',{staticClass:"breadcrumb-item"},[_vm._v("Thêm mới đánh giá")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"nav nav-tabs",attrs:{"role":"tablist"}},[_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link active",attrs:{"data-toggle":"tab","href":"#home"}},[_vm._v("Tiếng Việt")])]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"data-toggle":"tab","href":"#menu1"}},[_vm._v("English ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('strong',[_vm._v("Thông tin ")]),_c('small',[_vm._v("Form")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"name"}},[_vm._v("Tên"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-10"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"status"}},[_vm._v("Trạng thái ")]),_c('select',{staticClass:"form-control col-sm-3",attrs:{"name":"status"}},[_c('option',{attrs:{"value":"1"}},[_vm._v("Hiện")]),_c('option',{attrs:{"value":"2"}},[_vm._v("Ẩn")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('strong',[_vm._v("Department ")]),_c('small',[_vm._v("Form")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"name_EN"}},[_vm._v("Name "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])
}]

export { render, staticRenderFns }