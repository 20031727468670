<template>
  <div>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">Trang chủ</li>
      <li class="breadcrumb-item">
        <a href="/admin/questionrate/lists">Quản lý đánh giá </a>
      </li>

      <li class="breadcrumb-item">Thêm mới đánh giá</li>
    </ol>

    <form method="post" @submit.prevent="mySubmit" id="sendform">
      <div class="container">
        <ul class="nav nav-tabs" role="tablist">
          <li class="nav-item">
            <a class="nav-link active" data-toggle="tab" href="#home"
              >Tiếng Việt</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link" data-toggle="tab" href="#menu1">English </a>
          </li>
        </ul>

        <!-- Tab panes -->
        <div class="tab-content">
          <div id="home" class="container tab-pane active">
            <br />
            <div class="">
              <div class="col-sm-12">
                <div class="card">
                  <div class="card-header">
                    <strong>Thông tin </strong>
                    <small>Form</small>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-sm-10">
                        <div class="form-group">
                          <label for="name"
                            >Tên<span class="text-danger">*</span></label
                          >
                          <input type="text" name="name" class="form-control" />
                          <p class="text-danger">{{ error.name }}</p>
                        </div>
                      </div>

                      <div class="col-sm-10">
                        <div class="form-group">
                          <label for="status">Trạng thái </label>
                          <select class="form-control col-sm-3" name="status">
                            <option value="1">Hiện</option>
                            <option value="2">Ẩn</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="menu1" class="container tab-pane fade">
            <br />
            <div class="">
              <div class="col-sm-12">
                <div class="card">
                  <div class="card-header">
                    <strong>Department </strong>
                    <small>Form</small>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-sm-10">
                        <div class="form-group">
                          <label for="name_EN"
                            >Name <span class="text-danger">*</span></label
                          >
                          <input
                            type="text"
                            name="name_EN"
                            class="form-control"
                          />
                          <p class="text-danger">{{ error.name_EN }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="card-footer"
            style="width: 90%; position: fixed; bottom: 0"
          >
            <button
              type="submit"
              class="btn btn-sm btn-primary"
              v-if="!is_load"
            >
              <i class="fa fa-save"></i> Save
            </button>
            <h5 v-else>Loading...</h5>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      is_load: false,
      error: {
        name: "",
        name_EN: "",
      },
    };
  },
  mounted() {},

  methods: {
    alertError() {
      let tt = "";
      for (let i = 0; i < Object.values(this.error).length; i++) {
        if (Object.values(this.error)[i]) {
          tt =
            tt +
            "<ul style='text-align:left;'><li>" +
            Object.values(this.error)[i] +
            "</li></ul>";
        }
      }
      return tt;
    },
    mySubmit: function () {
      let myForm = document.getElementById("sendform");
      let formData = new FormData(myForm);
      for (var pair of formData) {
        if (pair[0] == "name") {
          if (pair[1] == "") {
            this.error.name = "Vui lòng nhập tên.";
          } else {
            this.error.name = "";
          }
        }

        if (pair[0] == "name_EN") {
          if (pair[1] == "") {
            this.error.name_EN = "Vui lòng nhập tên - Tiếng Anh.";
          } else {
            this.error.name_EN = "";
          }
        }
      }
      if (this.error.name == "" && this.error.name_EN == "") {
        this.is_load = true;
        axios
          .post(
            window.my_api + "api/questionrate/create-or-update-questionrate",
            formData
          )
          .then((res) => {
            if (res.status == 200) {
             alert("Thêm thành công.")
               this.$router.go(this.$router.currentRoute);
              document.getElementById("sendform").reset();
            } else {
              alert("Thêm không thành công, vui lòng liên hệ admin");
            }
            this.is_load = false;
          });
      } else {
        console.log(this.alertError());

alert("Vui lòng nhập đầy đủ");
      }
    },
  },
};
</script>